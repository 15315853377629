import React from 'react';
import Layout from '@/components/_layout';
import Seo from '@/components/_layout/seo';
import { graphql, PageProps } from 'gatsby';
import PageBanner from '@/components/teams-page-banner';
import { TermsContent } from '@/components/terms-and-conditions-content';

const PrivacyPolicy = ({ data, location, pageContext }: PageProps<any>): JSX.Element => {
  const {
    // @ts-ignore
    breadcrumb: { crumbs },
  } = pageContext;
  return (
    <Layout location={location} crumbs={crumbs}>
      <Seo
        title={
          data?.sanityPrivacyPageMetadata?.privacy_page_metadata?.pageMetaName || 'Privacy Policy'
        }
        description={data?.sanityPrivacyPageMetadata?.privacy_page_metadata?.pageMetaDescription}
        pageMetadata={data?.sanityPrivacyPageMetadata?.privacy_page_metadata?.metadata}
        canonical={data?.sanityPrivacyPageMetadata?.privacy_page_metadata?.canonical}
      />
      <PageBanner
        backgroundImageUrl={data?.TERMS_PAGE_BANNER}
        title={{ solid: 'Privacy Policy' }}
        isAddress={false}
        isOverlay={false}
      />
      <TermsContent body={data?.sanityPolicy?._rawDetails} />
    </Layout>
  );
};

export default PrivacyPolicy;

export const query = graphql`
  query {
    sanityPrivacyPageMetadata {
      privacy_page_metadata {
        metadata {
          metaContent
          metaName
        }
        pageMetaDescription
        pageMetaName
        canonical
      }
    }
    sanityPolicy {
      id
      title {
        is_outline_first
        title_solid
        title_outlined
      }
      _rawDetails
    }
    TERMS_PAGE_BANNER: file(relativePath: { eq: "award-overlay.png" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
  }
`;
